<template>
  <v-container fluid class="pa-0" :key="`reg_field_${active}`">
    <!-- Player Search -->
    <v-expand-transition>
      <div v-if="showSearch || (!isBidPlayer && !showFields && !isSpectators)">
        <v-row dense>
          <v-col cols="12" md="6" class="title">
            {{participantType ? `${participantType} ${playerI}` : ''}}
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" v-for="m in cartMemsAvail" :key="m.linkId">
            <v-btn color="color3 color3Text--text" @click.stop="useCartMem(m)">
              Use {{`${m.profile.firstName} ${m.profile.lastName}`}}
            </v-btn>
            <h1 class="pa-6">Or</h1>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" md="6" class="title">
            <div class="subheading" v-if="!tournament.membersOnly">Search for a {{participantType}} or click "Manual Entry" below</div>
            <div class="subheading" v-else>This is a members only tournament please search for a player below</div>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" md="6">
            <player-lookup
              :sbid="sanctioningBodyId"
              @player-selected="onPlayerSelected"
              :partners="partnerList"
              :registration="registration"
              @manual-click="onManualClick"
              :allowTbd="allowTbd"
              :membersOnly="tournament.membersOnly && !(user && user.vblx)"
              :acceptedLevels="tournament.membershipLevels"
              :newResults="true"
              :xPlayers="xPlayers"
            ></player-lookup>
          </v-col>
        </v-row>
        <v-row dense v-if="tournament.membersOnly">
          <v-col cols="12" md="6">
            Not a member?
            <v-btn
              color="color3 color3Text--text"
              small
              :href="joinNow"
            >
              <v-icon class="mr-3">fas fa-users-medical</v-icon>
              Join Here
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-expand-transition>
    <v-row dense v-if="!noTbd && allowTbd && (playerI > 1 || allowZeroPlayers) && mode !== 'change' && !player.playerProfileId">
      <tbd-dialog
        :tournament="tournament"
        :registration="registration"
        @tbd-click="onTbdClick"
      ></tbd-dialog>
    </v-row>
    <!-- Manual Button -->
    <v-row dense :justify="tournament.membersOnly ? 'end' : 'space-between'" style="min-height: 10px">
        <v-btn
          transition="expand-transition"
          v-if="!showFields && !isBidPlayer && !tournament.membersOnly && !isSpectators"
          color="color3"
          text
          class="xsmall pl-0 ml-0"
          @click.stop="manual = !manual"
        >Manual Entry</v-btn>
        <v-btn
          transition="expand-transition"
          v-if="showFields && !isBidPlayer && !tournament.membersOnly && !isSpectators"
          color="color3"
          text
          class="xsmall pl-0 ml-0"
          @click.stop="showSearch = !showSearch"
        >{{showSearch ? 'Hide ' : ''}}{{participantType}} lookup</v-btn>
        <v-btn
          color="color3"
          text
          class="xsmall pr-0 mr-0"
          v-if="player.playerProfileId && !isBidPlayer"
          @click.stop="resetPlayer"
        >reset</v-btn>
    </v-row>
    <!-- Fields -->
    <v-expand-transition>
      <div v-if="showFields || isSpectators">
        <v-row dense v-if="player.playerProfileId">
          <v-col cols="12" class="title">
            {{player.firstName}} {{player.lastName}}
            <div class="subheading mt-1">Please fill in any missing required fields and click next to continue</div>
          </v-col>
        </v-row>
        <!-- Sanctioning Body -->
        <template v-if="!isCollegeCoaches && !isSpectators">
          <!-- AAU -->
          <v-row dense  v-if="(isAAU || requiredFields.includes('aau')) && !international">
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                name="aau"
                :label="$v.player.aauNumber.hasOwnProperty('required') ? 'AAU Number*' : 'AAU Number'"
                v-model="player.aauNumber"
                persistent-hint
                :rules="$v.player.aauNumber.hasOwnProperty('required') && $v.player.aauNumber.$dirty ? [
                  () => $v.player.aauNumber.required || 'An AAU Number is required'
                ] : []"
                @blur="$v.player.aauNumber.$touch(); $emit('blur')"
                color="color3"
                :disabled="isOnFile('aauNumber')"
                :placeholder="isOnFile('aauNumber') ? 'On File' : null"
                :solo="player.playerProfileId && !player.aauNumber && !isOnFile('aauNumber')"
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="2" :class="{'text-center': $vuetify.breakpoint.xsOnly}" v-if="player.validAau || player.manualAau">
              <v-col class="green--text" v-if="player.validAau">
                <v-btn icon color="green white--text">
                  <v-icon>fas fa-check-circle</v-icon>
                </v-btn>
                Confirmed
              </v-col>
              <v-col v-if="player.manualAau">
                <v-btn icon color="amber amber--text text--darken-3">
                  <v-icon>fas fa-exclamation</v-icon>
                </v-btn>
                Manually Verified
              </v-col>
            </v-col>
            <v-col cols="6" md="2" :class="{'text-center': $vuetify.breakpoint.xsOnly}">
              <aau-validator :player="player" color='color3 color3Text--text' :onfile="isOnFile('aauNumber')"></aau-validator>
            </v-col>
          </v-row>
          <!-- p1440 -->
          <v-row dense v-if="isP1440 || requiredFields.includes('p1440')">
            <v-col class="text-center" cols="12" sm="6" md="4" >
              <v-text-field
                @click="!p1440OnFile && validate('p1440')"
                readonly
                name="p1440"
                :label="`p1440${elite ? ' Elite' : ''} Number${$v.player.p1440Number.hasOwnProperty('required') ? '*' : ''}`"
                v-model="player.p1440Number"
                persistent-hint
                :rules="$v.player.p1440Number.hasOwnProperty('required') && $v.player.p1440Number.$dirty ? [
                  () => $v.player.p1440Number.required || 'A p1440 number is required'
                ] : []"
                @blur="$v.player.p1440Number.$touch(); $emit('blur')"
                color="color3"
                :disabled="p1440OnFile"
                :placeholder="p1440OnFile ? 'On File' : null"
                :solo="player.playerProfileId && !player.p1440Number && !p1440OnFile"
              >
                <template v-slot:append-outer v-if="!p1440OnFile || player.p1440Status">
                  <v-btn icon color="green white--text" v-if="player.p1440Status === 'true'">
                    <v-icon>fas fa-check-circle</v-icon>
                  </v-btn>
                  <v-btn icon color="amber amber--text text--darken-3" v-else-if="player.p1440Status === 'expired'">
                    <v-icon>fas fa-exclamation-circle</v-icon>
                  </v-btn>
                  <v-btn icon color="error white--text" v-else-if="player.p1440Status === 'false'">
                    <v-icon>fas fa-times-circle</v-icon>
                  </v-btn>
                  <v-btn
                    v-else
                    color="color3 color3Text--text"
                    @click.stop="validate('p1440')"
                    small
                  >Verify</v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <!-- AVP -->
          <v-row dense  v-if="isAVP || requiredFields.includes('avp')">
            <v-col class="text-center" cols="12" sm="6" md="4">
              <v-text-field
                @click="!isOnFile('avpNumber') && validate('AVPA')"
                readonly
                name="avp"
                :label="$v.player.avpNumber.hasOwnProperty('required') ? 'AVP America Number*' : `AVP America Number${skipRequired('avp') ? ' (optional)' : ''}`"
                v-model="player.avpNumber"
                persistent-hint
                :rules="$v.player.avpNumber.hasOwnProperty('required') && $v.player.avpNumber.$dirty ? [
                  () => $v.player.avpNumber.required || 'An AVP America number is required'
                ] : []"
                @blur="$v.player.avpNumber.$touch(); $emit('blur')"
                color="color3"
                :disabled="isOnFile('avpNumber') || (isAVP && sbMemInCart)"
                :placeholder="isOnFile('avpNumber') ? 'On File' : (isAVP && sbMemInCart) ? 'TBD' : null"
                :solo="player.playerProfileId && !player.avpNumber && !isOnFile('avpNumber') && !(isAVP && sbMemInCart)"
              >
                <template v-slot:append-outer v-if="!isOnFile('avpNumber') || player.avpaStatus">
                  <v-btn icon color="green white--text" v-if="player.avpaStatus === 'true'">
                    <v-icon>fas fa-check-circle</v-icon>
                  </v-btn>
                  <v-btn icon color="amber amber--text text--darken-3" v-else-if="player.avpaStatus === 'expired'">
                    <v-icon>fas fa-exclamation-circle</v-icon>
                  </v-btn>
                  <v-btn icon color="error white--text" v-else-if="player.avpaStatus === 'false'">
                    <v-icon>fas fa-times-circle</v-icon>
                  </v-btn>
                  <v-btn
                    v-else
                    color="color3 color3Text--text"
                    @click.stop="validate('AVPA')"
                    small
                  >Verify</v-btn>
                </template>
              </v-text-field>
              <v-expand-transition>
                <membership-create-dialog
                  subdomain="avp"
                  :playerI="playerI"
                  :player="player"
                  v-if="oneCart && !sbMemInCart && player.avpaStatus !== 'true' && !isOnFile('avpNumber')"
                  :organization="registration.organization"
                  :expired="player.avpaStatus && player.avpaStatus.includes('xpire')"
                ></membership-create-dialog>
              </v-expand-transition>
              <v-dialog
                v-model="avpErrorDialog"
                max-width="500px"
                transition="dialog-transition"
              >
                <v-card class="pa-0">
                  <v-card-text class="pa-3">
                    <v-alert type="error" text prominent class="ma-3">
                      {{ avpError }}
                    </v-alert>
                  </v-card-text>
                  <v-card-actions class="justify-center">
                    <v-btn color="color3" text @click.stop="avpErrorDialog=false">Ok</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <template v-if="!isOnFile('avpNumber') && !sbMemInCart && false">
              <v-col cols="6" md="2" v-if="player.avpaStatus" :class="{'text-center': $vuetify.breakpoint.xsOnly}">
                <v-col class="green--text" v-if="player.avpaStatus === 'true'">
                  <v-btn icon color="green white--text">
                    <v-icon>fas fa-check-circle</v-icon>
                  </v-btn>
                  Confirmed
                </v-col>
                <v-col v-else-if="player.avpaStatus === 'expired'">
                  <v-btn icon color="amber amber--text text--darken-3">
                    <v-icon>fas fa-exclamation-circle</v-icon>
                  </v-btn>
                  Expired
                </v-col>
                <v-col class="error--text" v-else>
                  <v-btn icon color="error white--text">
                    <v-icon>fas fa-times-circle</v-icon>
                  </v-btn>
                  Invalid
                </v-col>
              </v-col>
              <v-col :cols="!player.avpaStatus ? 12 : 6" md="2" :class="{'text-center': $vuetify.breakpoint.xsOnly}">
                <avpa-validator
                  :player="player"
                  color='color3 color3Text--text'
                  :onfile="isOnFile('avpNumber')"
                  :required="requiredFields.includes('avp')"
                  @show-manual="() => { this.manual = true }"
                ></avpa-validator>
              </v-col>
              <v-col cols="12" v-if="(isAVP || requiredFields.includes('avp')) && player.avpaStatus && player.avpaStatus.includes('xpire')">
                <v-checkbox
                  v-if="!tournament.strict && false"
                  class="ma-0"
                  label="I will bring proof of renewal to the tournament"
                  v-model="player.manualAvp"
                  :error-messages="!player.manualAvp ? 'You must renew your Avp America Membership before the start of the tournament' : ''"
                ></v-checkbox>
                <v-alert type="error" :value="true" prominent text v-else-if="player.avpaStatus && player.avpaStatus.includes('will')">
                  This AVP membership expires prior to the tournament. Please renew before registering.
                </v-alert>
              </v-col>
            </template>
          </v-row>
          <!-- USAV -->
          <v-row dense v-if="isUSAV || requiredFields.includes('usav')">
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                @click="!isOnFile('usavNumber') && validate('USAV')"
                readonly
                name="usav"
                :label="$v.player.usavNumber.hasOwnProperty('required') ? 'USAV Number*' : 'USAV Number'"
                v-model="player.usavNumber"
                :rules="$v.player.usavNumber.hasOwnProperty('required') && $v.player.usavNumber.$dirty ? [() => $v.player.usavNumber.required || 'A USAV number is required'] : []"
                @blur="$v.player.usavNumber.$touch; $emit('blur')"
                color="color3"
                :disabled="isOnFile('usavNumber')"
                :placeholder="isOnFile('usavNumber') ? 'On File' : null"
                :solo="player.playerProfileId && !player.usavNumber && !isOnFile('usavNumber')"
                type="search"
              >
                <template v-slot:append-outer v-if="!isOnFile('usavNumber') || player.usavStatus">
                  <v-btn icon color="green white--text" v-if="player.usavStatus === 'true'">
                    <v-icon>fas fa-check-circle</v-icon>
                  </v-btn>
                  <v-btn icon color="amber amber--text text--darken-3" v-else-if="player.usavStatus === 'expired'">
                    <v-icon>fas fa-exclamation-circle</v-icon>
                  </v-btn>
                  <v-btn icon color="error white--text" v-else-if="player.usavStatus === 'false'">
                    <v-icon>fas fa-times-circle</v-icon>
                  </v-btn>
                  <v-btn
                    v-else
                    color="color3 color3Text--text"
                    @click.stop="validate('USAV')"
                    small
                  >Verify</v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </template>
        <!-- Name -->
        <v-row dense>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              name="firstName"
              label="First Name*"
              v-model="player.firstName"
              @blur="$v.player.firstName.$touch()"
              :rules="$v.player.firstName.$dirty ? [() => $v.player.firstName.required || 'A first name is required'] : []"
              color="color3"
              :disabled="isOnFile('firstName') || (isAVP && ['true', 'expired'].includes(player.avpaStatus))"
              :placeholder="isOnFile('firstName') ? 'On File' : null"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              name="lastName"
              label="Last Name*"
              v-model="player.lastName"
              :rules="$v.player.lastName.$dirty ? [() => $v.player.lastName.required || 'A last name is required'] : []"
              @blur="$v.player.lastName.$touch(); $emit('blur')"
              color="color3"
              :disabled="isOnFile('lastName') || (isAVP && ['true', 'expired'].includes(player.avpaStatus))"
              :placeholder="isOnFile('lastName') ? 'On File' : null"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" v-if="_fields.includes('gender') || _fields.includes('*')">
            <v-select
              :items="[
                { text: 'Female', value: false},
                { text: 'Male', value: true}
              ]"
              v-model="player.male"
              :label="$v.player.male.hasOwnProperty('required') ? 'Gender*' : 'Gender'"
              @blur="$v.player.male.$touch()"
              :rules="$v.player.male.hasOwnProperty('required') && $v.player.male.$dirty ? [
                () => $v.player.male.required || 'A gender is required'
              ] : []"
              color="color3"
              item-color="color3"
              :disabled="isOnFile('gender')"
              :placeholder="isOnFile('gender') ? 'On File' : null"
              :solo="!!player.playerProfileId"
            ></v-select>
          </v-col>
        </v-row>
        <!-- international -->
        <v-row dense v-if="international">
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              label="Passport Number"
              v-model="player.passport"
              @blur="$emit('blur')"
              color="color3"
              :solo="player.playerProfileId && !player.passport && !isOnFile('passport')"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              label="AAU Number"
              v-model="player.aauNumber"
              @blur="$emit('blur')"
              color="color3"
              hint="If the player already has an AAU Number, enter it here."
              persistent-hint
              :disabled="isOnFile('aauNumber')"
              :solo="player.playerProfileId && !player.aauNumber && !isOnFile('aauNumber')"
              :placeholder="isOnFile('aauNumber') ? 'On File' : null"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- Phone/Email -->
        <v-row dense>
          <v-col cols="12" sm="6" md="4" v-if="_fields.includes('phone') || _fields.includes('*') || (requiredFields.includes('2FPhone') && playerI === 1)">
            <v-text-field
              v-if="!isInternational"
              key="intPhone"
              name="phone"
              :label="$v.player.phone.hasOwnProperty('required') ? 'Mobile Phone*' : 'Mobile Phone'"
              v-model="player.phone"
              v-mask="'(###) ###-####'"
              :error-messages="phoneErrors"
              @blur="$v.player.phone.$touch(); $emit('blur')"
              @focus="$refs.twoFactor && $refs.twoFactor.btnClick()"
              color="color3"
              :disabled="isOnFile('phone')"
              :placeholder="isOnFile('phone') ? 'On File' : null"
              :solo="player.playerProfileId && !player.phone && !isOnFile('phone')"
            >
              <template v-slot:append v-if="requiredFields.includes('2FPhone') && playerI === 1">
                <two-factor-phone
                  label="Mobile Phone*"
                  :model="player"
                  ref="twoFactor"
                  @verified="on2FVerified"
                  @verified-player="on2FVerifiedPlayer"
                ></two-factor-phone>
              </template>
            </v-text-field>
            <v-text-field
              v-else
              key="NonIntPhone"
              name="phone"
              :label="$v.player.phone.hasOwnProperty('required') ? 'Mobile Phone*' : 'Mobile Phone'"
              v-model="player.phone"
              :error-messages="phoneErrors"
              @blur="$v.player.phone.$touch(); $emit('blur')"
              color="color3"
              :disabled="isOnFile('phone')"
              :placeholder="isOnFile('phone') ? 'On File' : null"
              :solo="player.playerProfileId && !player.phone && !isOnFile('phone')"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" v-if="_fields.includes('email') || _fields.includes('emailConfirm') || _fields.includes('*')">
            <v-text-field
              name="email"
              :label="$v.player.email.hasOwnProperty('required') ? 'Email*' : 'Email'"
              v-model="player.email"
              :rules="$v.player.email.hasOwnProperty('required') && $v.player.email.$dirty ? [
                () => $v.player.email.required || 'An email is required',
                () => $v.player.email.email || 'A valid email is required'
              ] : []"
              @blur="$v.player.email.$touch(); $emit('blur')"
              color="color3"
              :disabled="isOnFile('email')"
              :placeholder="isOnFile('email') ? player.maskedEmail : null"
              :solo="player.playerProfileId && !player.email && !isOnFile('email')"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" v-if="_fields.includes('emailConfirm') || _fields.includes('*')">
            <v-text-field
              name="emailConfirm"
              label="Confirm Email*"
              v-model="emailConfirm"
              :rules="$v.emailConfirm.hasOwnProperty('required') && $v.emailConfirm.$dirty ? [
                () => $v.emailConfirm.required || 'An email is required',
                () => $v.emailConfirm.sameAsEmail || 'The emails do not match'
              ] : []"
              @blur="$v.emailConfirm.$touch(); $emit('blur')"
              color="color3"
              :disabled="isOnFile('email')"
              :placeholder="isOnFile('email') ? player.maskedEmail : null"
              :solo="player.playerProfileId && !emailConfirm && !isOnFile('email')"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- Country -->
        <v-row dense>
          <v-col cols="12" sm="6" md="4" v-if="_fields.includes('country') || _fields.includes('*')">
            <v-autocomplete
              :label="`Country ${$v.player.country.hasOwnProperty('required') ? '*' : ''}`"
              :items="countries"
              item-text="name"
              item-value="name"
              v-model="player.country"
              :hint="player.country ? '' : 'Please select your country'"
              persistent-hint
              color="color3"
              item-color="color3"
              autocomplete="none"
              type="search"
              @blur="$v.player.country.$touch()"
              :rules="$v.player.country.hasOwnProperty('required') && $v.player.country.$dirty ? [
                () => $v.player.country.required || 'Country is required'
              ] : []"
              :disabled="isOnFile('country')"
              :placeholder="isOnFile('country') ? 'On File' : null"
              :solo="player.playerProfileId && !player.country && !isOnFile('country')"
            ></v-autocomplete>
          </v-col>

        </v-row>
        <!-- Address -->
        <template v-if="_fields.includes('fullAddress') || _fields.includes('*')">
          <v-row dense>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                name="address1"
                :label="$v.player.address.hasOwnProperty('required') ? 'Address line 1*' : 'Address line 1'"
                v-model="player.address"
                :rules="$v.player.address.hasOwnProperty('required') && $v.player.address.$dirty ? [
                  () => $v.player.address.required || 'An address is required'
                ] : []"
                @blur="$v.player.address.$touch()"
                color="color3"
                :disabled="isOnFile('address')"
                :placeholder="isOnFile('address') ? 'On File' : null"
                :solo="player.playerProfileId && !player.address && !isOnFile('address')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                name="address2"
                label="Address line 2"
                v-model="player.address2"
                color="color3"
                :disabled="isOnFile('address')"
                :placeholder="isOnFile('address') ? 'On File' : null"
                :solo="player.playerProfileId && !player.address && !isOnFile('address')"
              ></v-text-field>
            </v-col>
          </v-row>
        </template>
        <!-- City State Zip -->
        <v-row dense v-if="_fields.includes('fullAddress') || _fields.includes('cityState') || _fields.includes('*')">
          <!-- city -->
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              name="city"
              :label="$v.player.city.hasOwnProperty('required') ? 'City*' : 'City'"
              v-model="player.city"
              @blur="$v.player.city.$touch()"
              :rules="$v.player.city.hasOwnProperty('required') && $v.player.city.$dirty ? [
                () => $v.player.city.required || 'A city is required'
              ] : []"
              color="color3"
              :disabled="isOnFile('city')"
              :placeholder="isOnFile('city') ? 'On File' : null"
              :solo="player.playerProfileId && !player.city && !isOnFile('city')"
            ></v-text-field>
          </v-col>
          <template v-if="!isInternational">
            <!-- state -->
            <v-col :cols="_fields.includes('fullAddress') ? 6 : 12" :sm="_fields.includes('fullAddress') ? 3 : 6" :md="_fields.includes('fullAddress') ? 2 : 4">
              <v-autocomplete
                :label="$v.player.state.hasOwnProperty('required') ? 'State*' : 'State'"
                v-bind:items="states"
                item-text="abbreviation"
                item-value="abbreviation"
                v-model="player.state"
                @blur="$v.player.state.$touch()"
                :rules="$v.player.state.hasOwnProperty('required') && $v.player.state.$dirty ? [
                  () => $v.player.state.required || 'A state is required'
                ] : []"
                clearable
                color="color3"
                :disabled="isOnFile('state')"
                :placeholder="isOnFile('state') ? 'On File' : null"
                :solo="player.playerProfileId && !player.state && !isOnFile('state')"
              ></v-autocomplete>
            </v-col>
            <!-- zip -->
            <v-col cols="6" sm="3" md="2" v-if="_fields.includes('fullAddress') || _fields.includes('*')">
              <v-text-field
                name="zip"
                :label="$v.player.zip.hasOwnProperty('required') ? 'Zip*' : 'Zip'"
                v-model="player.zip"
                v-mask="'#####'"
                @blur="$v.player.zip.$touch()"
                :rules="$v.player.zip.hasOwnProperty('required') && $v.player.zip.$dirty ? [
                  () => $v.player.zip.required || 'A zip is required'
                ] : []"
                color="color3"
                :disabled="isOnFile('zip')"
                :placeholder="isOnFile('zip') ? 'On File' : null"
                :solo="player.playerProfileId && !player.zip && !isOnFile('zip')"
              ></v-text-field>
            </v-col>
          </template>
          <template v-else>
            <!-- state -->
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                :label="$v.player.state.hasOwnProperty('required') ? 'State/Province/Region*' : 'State/Province/Region'"
                v-model="player.state"
                @blur="$v.player.state.$touch()"
                :rules="$v.player.state.hasOwnProperty('required') && $v.player.state.$dirty ? [
                  () => $v.player.state.required || 'A State/Province/Region is required'
                ] : []"
                color="color3"
                :disabled="isOnFile('state')"
                :placeholder="isOnFile('state') ? 'On File' : null"
                :solo="player.playerProfileId && !player.state && !isOnFile('state')"
              ></v-text-field>
            </v-col>
            <!-- zip -->
            <v-col cols="12" sm="6" md="4" v-if="_fields.includes('fullAddress') || _fields.includes('*')">
              <v-text-field
                name="zip"
                :label="$v.player.zip.hasOwnProperty('required') ? 'ZIP/Postal Code*' : 'ZIP/Postal Code'"
                v-model="player.zip"
                @blur="$v.player.zip.$touch()"
                :rules="$v.player.zip.hasOwnProperty('required') && $v.player.zip.$dirty ? [
                  () => $v.player.zip.required || 'A ZIP/Postal Code is required'
                ] : []"
                color="color3"
                :disabled="isOnFile('zip')"
                :placeholder="isOnFile('zip') ? 'On File' : null"
                :solo="player.playerProfileId && !player.zip && !isOnFile('zip')"
              ></v-text-field>
            </v-col>
          </template>
        </v-row>
        <!-- DOB, Grad Year, HS, Club, Shirt Size, Height -->
        <v-row dense>
          <!-- DOB -->
          <v-col cols="12" sm="6" md="4" v-if="_fields.includes('dob') || _fields.includes('*')">
            <v-dialog
              ref="dobPicker"
              v-model="dobPicker"
              width="290px"
              :return-value.sync="player.dob"
              :disabled="dobOnFile"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-on="on"
                  :label="$v.player.dob.hasOwnProperty('required') ? 'Birthdate*' : 'Birthdate'"
                  readonly
                  v-model="player.dobF"
                  :error-messages="dobErrors"
                  :disabled="dobOnFile"
                  :placeholder="dobOnFile ? 'On File' : null"
                  :solo="player.playerProfileId && !player.dob && !dobOnFile"
                  :clearable="!requiredFields.includes('dob') && !dobOnFile"
                  persistent-hint
                  :hint="`What is ${player.firstName || playerString}'s birthdate?`"
                ></v-text-field>
              </template>
              <v-date-picker
                ref="dobPickerPicker"
                v-model="player.dob"
                :color="chipColor"
                @change="$refs.dobPicker.save(player.dob); $v.player.dob.$touch()"
              ></v-date-picker>
            </v-dialog>
            <v-checkbox
              v-if="validateHS"
              :label="`${player.firstName || 'Player'} attended high school this academic year`"
              v-model="attendHS"
              hide-details
              @change="$v.player.dob.$touch"
              color="success"
            ></v-checkbox>
          </v-col>
          <!-- GRAD YEAR -->
          <v-col cols="12" sm="6" md="4" v-if="_fields.includes('gradyear') || _fields.includes('*')">
            <v-autocomplete
              :items="gradYears"
              :label="$v.player.gradYear.hasOwnProperty('required') ? 'Graduation Year*' : 'Graduation Year'"
              v-model="player.gradYear"
              @blur="$v.player.gradYear.$touch()"
              :error-messages="gradYearErrors"
              single-line
              clearable
              :hint="`When does ${player.firstName || playerString} graduate high school?`"
              persistent-hint
              color="color3"
              :disabled="isOnFile('gradYear')"
              :placeholder="isOnFile('gradYear') ? 'On File' : null"
              :solo="player.playerProfileId && !player.gradYear && !isOnFile('gradYear')"
              menu-props="auto"
              autocomplete="false"
              type="search"
            ></v-autocomplete>
          </v-col>
          <!-- HS -->
          <v-col cols="12" sm="6" md="4"  v-if="_fields.includes('ibvl')">
            <v-autocomplete
              :label="$v.player.highSchool.hasOwnProperty('required') ? 'School*' : 'School'"
              v-model="player.highSchool"
              @blur="$v.player.highSchool.$touch()"
              :rules="$v.player.highSchool.hasOwnProperty('required') && $v.player.highSchool.$dirty ? [
                () => $v.player.highSchool.required || 'A school is required'
              ] : []"
              :items="ibvl"
              :clearable="!requiredFields.includes('ibvl')"
              :disabled="schoolBlock"
              color="color3"
              :solo="player.playerProfileId && !player.highSchool && !schoolBlock"
              :hint="`What school does ${player.firstName || playerString} attend?`"
              persistent-hint
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4" v-else-if="_fields.includes('highSchool') || _fields.includes('*')">
            <v-text-field
              name="highSchool"
              :label="$v.player.highSchool.hasOwnProperty('required') ? `School*` : `School`"
              v-model="player.highSchool"
              @blur="$v.player.highSchool.$touch()"
              :rules="$v.player.highSchool.hasOwnProperty('required') && $v.player.highSchool.$dirty ? [
                () => $v.player.highSchool.required || `A school is required`
              ] : []"
              :disabled="schoolBlock || isOnFile('highSchool')"
              color="color3"
              :placeholder="isOnFile('highSchool') ? 'On File' : null"
              :solo="player.playerProfileId && !player.highSchool && !isOnFile('highSchool')"
              :hint="isCollegeCoaches ? 'What College are you coaching for?' : `What school does ${player.firstName || playerString} attend?`"
              persistent-hint
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" v-if="schoolBlock">
            <v-alert type="info" :value="true">
              Athletes must attend the same school
            </v-alert>
          </v-col>
          <!-- GPA -->
          <v-col cols="12" sm="6" md="4" v-if="_fields.includes('gpa') || _fields.includes('*')">
            <v-text-field
              name="gpa"
              :label="$v.player.gpa.hasOwnProperty('required') ? 'GPA*' : 'GPA'"
              v-model="player.gpa"
              @blur="$v.player.gpa.$touch()"
              :rules="$v.player.gpa.hasOwnProperty('required') && $v.player.gpa.$dirty ? [
                () => $v.player.gpa.required || 'A GPA is required'
              ] : []"
              color="color3"
              :disabled="isOnFile('gpa')"
              :placeholder="isOnFile('gpa') ? 'On File' : null"
              :solo="player.playerProfileId && !player.gpa && !isOnFile('gpa')"
              type="number"
            ></v-text-field>
          </v-col>
          <!-- Club -->
          <v-col cols="12" sm="6" md="4" v-if="_fields.includes('club') || _fields.includes('*')">
            <v-text-field
              name="club"
              :label="$v.player.club.hasOwnProperty('required') ? 'Club*' : 'Club'"
              v-model="player.club"
              :hint="`What club is ${player.firstName || playerString} representing?`"
              persistent-hint
              @blur="$v.player.club.$touch()"
              :rules="$v.player.club.hasOwnProperty('required') && $v.player.club.$dirty ? [
                () => $v.player.club.required || 'A club is required'
              ] : []"
              color="color3"
              :disabled="isOnFile('club')"
              :placeholder="isOnFile('club') ? 'On File' : null"
              :solo="player.playerProfileId && !player.club && !isOnFile('club')"
            ></v-text-field>
          </v-col>
          <!-- Instagram -->
          <v-col cols="12" sm="6" md="4" v-if="_fields.includes('instagram') || _fields.includes('social') || _fields.includes('*')">
            <v-text-field
              name="instagram"
              :label="$v.player.instagram.hasOwnProperty('required') ? `Instagram*` : `Instagram`"
              v-model="player.instagram"
              @blur="$v.player.instagram.$touch()"
              :rules="$v.player.instagram.hasOwnProperty('required') && $v.player.instagram.$dirty ? [
                () => $v.player.instagram.required || 'An instagram handle is required'
              ] : []"
              color="color3"
              :hint="`What is your Instagram handle?`"
              persistent-hint
              :disabled="isOnFile('instagram')"
              :placeholder="isOnFile('instagram') ? 'On File' : null"
              :solo="player.playerProfileId && !player.instagram && !isOnFile('instagram')"
            ></v-text-field>
          </v-col>
          <!-- Shirt Size -->
          <v-col cols="12" sm="6" md="4" v-if="_fields.includes('shirtSize') || _fields.includes('*')">
            <v-select
              :items="shirtSizes"
              v-model="player.shirtSize"
              :label="$v.player.shirtSize.hasOwnProperty('required') ? 'T-Shirt Size*' : 'T-Shirt Size'"
              clearable
              color="color3"
              item-color="color3"
              @blur="$v.player.shirtSize.$touch()"
              :rules="$v.player.shirtSize.hasOwnProperty('required') && $v.player.shirtSize.$dirty ? [
                () => $v.player.shirtSize.required || 'A t-shirt size is required'
              ] : []"
              :disabled="isOnFile('shirtSize')"
              :placeholder="isOnFile('shirtSize') ? 'On File' : null"
              :solo="player.playerProfileId && !player.shirtSize && !isOnFile('shirtSize')"
              :hint="`What is ${player.firstName || playerString}'s adult shirt size?`"
              persistent-hint
            ></v-select>
          </v-col>
          <!-- Height -->
          <v-col cols="12" sm="6" md="4" v-if="_fields.includes('height') || _fields.includes('*')">
            <v-select
              :items="heightOptions"
              v-model="player.height"
              :label="$v.player.height.hasOwnProperty('required') ? 'Height*' : 'Height'"
              :hint="`How tall is ${player.firstName || playerString}?`"
              persistent-hint
              clearable
              color="color3"
              item-color="color3"
              @blur="$v.player.height.$touch()"
              :rules="$v.player.height.hasOwnProperty('required') && $v.player.height.$dirty ? [
                () => $v.player.height.required || 'Height is required'
              ] : []"
              :disabled="isOnFile('height')"
              :placeholder="isOnFile('height') ? 'On File' : null"
              :solo="player.playerProfileId && !player.height && !isOnFile('height')"
              @focus="heightFocus"
              menu-props="auto"
            ></v-select>
          </v-col>
          <!-- clubDirectorName -->
          <v-col cols="12" sm="6" md="4" v-if="_fields.includes('clubDirectorName') || _fields.includes('*')">
            <v-text-field
              name="clubDirectorName"
              :label="$v.player.clubDirectorName.hasOwnProperty('required') ? 'Club Director/Coach\'s Name*' : 'Club Director/Coach\'s Name'"
              v-model="player.clubDirectorName"
              :hint="`Who is ${player.firstName || playerString}'s club director/coach?`"
              persistent-hint
              @blur="$v.player.clubDirectorName.$touch()"
              :rules="$v.player.clubDirectorName.hasOwnProperty('required') && $v.player.clubDirectorName.$dirty ? [
                () => $v.player.clubDirectorName.required || 'Club director/coach\'s name is required'
              ] : []"
              color="color3"
              :disabled="isOnFile('clubDirectorName')"
              :placeholder="isOnFile('clubDirectorName') ? 'On File' : null"
              :solo="player.playerProfileId && !player.clubDirectorName && !isOnFile('clubDirectorName')"
            ></v-text-field>
          </v-col>
          <!-- clubDirectorEmail -->
          <v-col cols="12" sm="6" md="4" v-if="_fields.includes('clubDirectorEmail') || _fields.includes('*')">
            <v-text-field
              name="clubDirectorEmail"
              :label="$v.player.clubDirectorEmail.hasOwnProperty('required') ? 'Club Director/Coach\'s Email*' : 'Club Director/Coach\'s Email'"
              v-model="player.clubDirectorEmail"
              :hint="`What is ${player.firstName || playerString}'s club director/coach's email?`"
              persistent-hint
              @blur="$v.player.clubDirectorEmail.$touch()"
              :rules="$v.player.clubDirectorEmail.hasOwnProperty('required') && $v.player.clubDirectorEmail.$dirty ? [
                () => $v.player.clubDirectorEmail.required || 'Club director/coach\'s email is required',
                () => $v.player.clubDirectorEmail.email || 'A valid email is required'
              ] : []"
              color="color3"
              :disabled="isOnFile('clubDirectorEmail')"
              :placeholder="isOnFile('clubDirectorEmail') ? 'On File' : null"
              :solo="player.playerProfileId && !player.clubDirectorEmail && !isOnFile('clubDirectorEmail')"
            ></v-text-field>
          </v-col>
          <!-- Years Experience -->
          <v-col cols="12" sm="6" md="4" v-if="_fields.includes('yearsExp') || _fields.includes('*')">
            <v-select
              type="number"
              :items="[1,2,3,4,5,6,7,8,9]"
              name="yearsExp"
              :label="`Beach Experience${$v.player.yearsExp.hasOwnProperty('required') ? '*' : ''}`"
              v-model="player.yearsExp"
              :hint="`How many years of beach experience does  ${player.firstName || playerString} have?`"
              persistent-hint
              @blur="$v.player.yearsExp.$touch()"
              :rules="$v.player.yearsExp.hasOwnProperty('required') && $v.player.yearsExp.$dirty ? [
                () => $v.player.yearsExp.required || 'Beach experience is required',
                () => $v.player.yearsExp.integer || 'Please round to the nearest year'
              ] : []"
              color="color3"
              :disabled="isOnFile('yearsExp')"
              :placeholder="isOnFile('yearsExp') ? 'On File' : null"
              :solo="player.playerProfileId && !player.yearsExp && !isOnFile('yearsExp')"
            ></v-select>
          </v-col>
          <!-- Dominant Hand -->
          <v-col cols="12" sm="6" md="4" v-if="_fields.includes('hand') || _fields.includes('*')">
            <v-select
              type="number"
              name="hand"
              :items="[
                  'Right',
                  'Left',
                  'Ambidextrous'
                ]"
              :label="`Dominant Hand ${$v.player.hand.hasOwnProperty('required') ? '*' : ''}`"
              v-model="player.hand"
              :hint="`What is ${player.firstName || playerString}'s Dominant Hand?`"
              persistent-hint
              @blur="$v.player.hand.$touch()"
              :rules="$v.player.hand.hasOwnProperty('required') && $v.player.hand.$dirty ? [
                () => $v.player.hand.required || 'A dominant hand is required'
              ] : []"
              color="color3"
              item-color="color3"
              :disabled="isOnFile('hand')"
              :placeholder="isOnFile('hand') ? 'On File' : null"
              :solo="player.playerProfileId && !player.hand && !isOnFile('hand')"
            ></v-select>
          </v-col>
          <!-- Preferred Side -->
          <v-col cols="12" sm="6" md="4" v-if="_fields.includes('side') || _fields.includes('*')">
            <v-select
              name="side"
              :items="['Left', 'Right', 'No Preference']"
              :label="`Preferred Side ${$v.player.side.hasOwnProperty('required') ? '*' : ''}`"
              v-model="player.side"
              :hint="`What is ${player.firstName || playerString}'s Preferred Side?`"
              persistent-hint
              @blur="$v.player.side.$touch()"
              :rules="$v.player.side.hasOwnProperty('required') && $v.player.side.$dirty ? [
                () => $v.player.side.required || 'A preferred side is required'
              ] : []"
              color="color3"
              item-color="color3"
              :disabled="isOnFile('side')"
              :placeholder="isOnFile('side') ? 'On File' : null"
              :solo="player.playerProfileId && !player.side && !isOnFile('side')"
            ></v-select>
          </v-col>
          <!-- sandRecruitsNumber -->
          <v-col cols="12" sm="6" md="4" v-if="_fields.includes('sandRecruitsNumber') || _fields.includes('*')">
            <v-text-field
              type="number"
              name="sandRecruitsNumber"
              :label="$v.player.sandRecruitsNumber.hasOwnProperty('required') ? 'Sand Recruits Number*' : 'Sand Recruits Number'"
              v-model="player.sandRecruitsNumber"
              :hint="`What is ${player.firstName || playerString}'s Sand Recruits #?`"
              persistent-hint
              @blur="$v.player.sandRecruitsNumber.$touch()"
              :rules="$v.player.sandRecruitsNumber.hasOwnProperty('required') && $v.player.sandRecruitsNumber.$dirty ? [
                () => $v.player.sandRecruitsNumber.required || 'A Sand Recruits number is required'
              ] : []"
              color="color3"
              :disabled="isOnFile('sandRecruitsNumber')"
              :placeholder="isOnFile('sandRecruitsNumber') ? 'On File' : null"
              :solo="player.playerProfileId && !player.sandRecruitsNumber && !isOnFile('sandRecruitsNumber')"
            ></v-text-field>
          </v-col>
          <!-- classification -->
          <v-col cols="12" sm="6" md="4" v-if="_fields.includes('classification') || _fields.includes('*')">
            <v-select name="classification"
              :items="[1,2,3,4,5]"
              :label="$v.player.classification.hasOwnProperty('required') ? 'Classification*' : 'Classification'"
              v-model="player.classification"
              :hint="`What level did ${player.firstName || playerString}'s pair usually play during the season?`"
              persistent-hint
              @blur="$v.player.classification.$touch()"
              :rules="$v.player.classification.hasOwnProperty('required') && $v.player.classification.$dirty ? [
              ()=>
              $v.player.classification.required || 'A classification is required'
              ] : []"
              color="color3"
              item-color="color3"
              :disabled="isOnFile('classification')"
              :placeholder="isOnFile('classification') ? 'On File' : null"
              :solo="player.playerProfileId && !player.classification && !isOnFile('classification')"
              >
            </v-select>
          </v-col>
          <!-- Additional Fields -->
          <additional-field
            v-for="(field, i) in additionalFields" :key="`${field.label}-${i}`"
            :registration="registration"
            :field="field"
            :fieldI="i"
            :player="player"
            :playerI="playerI"
            :solo="!!player.playerProfileId"
            @valid-change="onAdditionValidChange"
          ></additional-field>
      </v-row>
        <!-- committed -->
        <committed-form-fields
          v-if="_fields.includes('committed') || _fields.includes('*')"
          :player="player"
          :disable="isOnFile('committed')"
          :collegeList="collegeList"
          :errors="errors"
          :required="$v.player._committed.hasOwnProperty('required')"
          @blur-commit="$v.player._committed.$touch()"
          @blur-commit-school="$v.player.committedSchool.$touch()"
          :commitSolo="player.playerProfileId && player._committed === null && !isOnFile('committed')"
          :committedSchoolSolo="player.playerProfileId && player.committed && !player.committedSchool && !isOnFile('committedSchool')"
          :commitHint="`Is ${player.firstName || playerString} already committed to a college?`"
          :committedSchoolHint="`What college is ${player.firstName || playerString} committed to?`"
          :commitPlaceholder="isOnFile('committed') ? 'On File' : null"
          :committedSchoolPlaceholder="isOnFile('committedSchool') ? 'On File' : null"
          :noCommittedAs="true"
        ></committed-form-fields>
        <v-row v-if="(_fields.includes('committed') || _fields.includes('*')) && false">
          <v-col cols="12" sm="3" md="2">
            <v-select
              :items="[{value: true, text: 'Yes'}, {value: false, text: 'No'}]"
              v-model="player.committed"
              :label="$v.player._committed.hasOwnProperty('required') ? 'Committed*' : 'Committed'"
              :hint="`Is ${player.firstName || playerString} already committed to a school?`"
              persistent-hint
              color="color3"
              item-color="color3"
              :disabled="isOnFile('committed')"
              :placeholder="isOnFile('committed') ? 'On File' : null"
              :solo="player.playerProfileId && player._committed === null && !isOnFile('committed')"
              @blur="$v.player._committed.$touch()"
              :rules="$v.player._committed.hasOwnProperty('required') && $v.player._committed.$dirty ? [
                () => $v.player._committed.required || 'Yes or No is required'
              ] : []"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="9" md="6">
            <v-text-field
              name="committedSchool"
              :label="$v.player.committedSchool.hasOwnProperty('required') ? 'School Committed To*' : 'School Committed To'"
              v-model="player.committedSchool"
              :hint="`What school is ${player.firstName || playerString} committed to?`"
              :persistent-hint="player.committed"
              :disabled="!player.committed || isOnFile('committedSchool')"
              @blur="$v.player.committedSchool.$touch()"
              :rules="$v.player.committedSchool.hasOwnProperty('required') && $v.player.committedSchool.$dirty ? [
                () => $v.player.committedSchool.required || 'A school is required'
              ] : []"
              color="color3"
              :placeholder="isOnFile('committedSchool') ? 'On File' : null"
              :solo="player.playerProfileId && player.committed && !player.committedSchool && !isOnFile('committedSchool')"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- Additional Numbers -->
        <v-row dense>
          <!-- AAU -->
          <v-col cols="12" sm="6" md="4" v-if="(fields.includes('aau') || _fields.includes('*')) && !isAAU">
            <v-text-field
              name="aau"
              :label="$v.player.aauNumber.hasOwnProperty('required') ? 'AAU Number*' : 'AAU Number'"
              v-model="player.aauNumber"
              :hint="requiredFields.includes('aau') ? '' : _fields.includes('aau') ? 'Not required but used for seeding': ''"
              persistent-hint
              :rules="$v.player.aauNumber.hasOwnProperty('required') && $v.player.aauNumber.$dirty ? [
                () => $v.player.aauNumber.required || 'An AAU Number is required'
              ] : []"
              @blur="$v.player.aauNumber.$touch(); $emit('blur')"
              color="color3"
              :disabled="isOnFile('aauNumber')"
              :placeholder="isOnFile('aauNumber') ? 'On File' : null"
              :solo="player.playerProfileId && !player.aauNumber && !isOnFile('aauNumber')"
            ></v-text-field>
          </v-col>
          <!-- AVP -->
          <v-col cols="12" sm="6" md="4" v-if="(fields.includes('avp') || _fields.includes('*')) && !isAVP">
            <v-text-field
              name="avp"
              :label="$v.player.avpNumber.hasOwnProperty('required') ? 'AVP Number*' : 'AVP Number'"
              v-model="player.avpNumber"
              :hint="requiredFields.includes('avp') ? '' : _fields.includes('avp') ? 'Not required but used for seeding': ''"
              persistent-hint
              :rules="$v.player.avpNumber.hasOwnProperty('required') && $v.player.avpNumber.$dirty ? [
                () => $v.player.avpNumber.required || 'An AVP America number is required'
              ] : []"
              @blur="$v.player.avpNumber.$touch(); $emit('blur')"
              color="color3"
              :disabled="isOnFile('avpNumber')"
              :placeholder="isOnFile('avpNumber') ? 'On File' : null"
              :solo="player.playerProfileId && !player.avpNumber && !isOnFile('avpNumber')"
            ></v-text-field>
          </v-col>
          <!-- USAV -->
          <v-col cols="12" sm="6" md="4" v-if="(_fields.includes('usav') || _fields.includes('*')) && !isUSAV">
            <v-text-field
              name="usav"
              :label="$v.player.usavNumber.hasOwnProperty('required') ? 'USAV Number*' : 'USAV Number'"
              v-model="player.usavNumber"
              :hint="requiredFields.includes('usav') ? '' : _fields.includes('usav') ? 'Not required but used for seeding': ''"
              persistent-hint
              :rules="$v.player.usavNumber.hasOwnProperty('required') && $v.player.usavNumber.$dirty ? [
                () => $v.player.usavNumber.required || 'A USAV America number is required'
              ] : []"
              @blur="$v.player.usavNumber.$touch(); $emit('blur')"
              color="color3"
              :disabled="isOnFile('usavNumber')"
              :placeholder="isOnFile('usavNumber') ? 'On File' : null"
              :solo="player.playerProfileId && !player.usavNumber && !isOnFile('usavNumber')"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- PLAYER Phone/Email -->
        <v-row dense v-if="_fields.includes('playerPhone') || _fields.includes('playerEmail') || _fields.includes('*')">
          <v-col cols="12" sm="6" md="4" v-if="_fields.includes('playerPhone') || _fields.includes('*')">
            <v-text-field
              v-if="!isInternational"
              key="intPhone2"
              name="playerPhone"
              :label="$v.player.playerPhone.hasOwnProperty('required') ? 'Player\'s Mobile Phone*' : 'Player\'s Mobile Phone'"
              v-model="player.playerPhone"
              v-mask="'(###) ###-####'"
              :rules="$v.player.playerPhone.hasOwnProperty('required') && $v.player.playerPhone.$dirty ? [
                () => $v.player.playerPhone.required || 'A mobile phone number is required',
                () => isInternational || $v.player.playerPhone.ValidUSPhone || 'A valid mobile phone number is required',
              ] : []"
              @blur="$v.player.playerPhone.$touch(); $emit('blur')"
              color="color3"
              :disabled="isOnFile('playerPhone')"
              :placeholder="isOnFile('playerPhone') ? 'On File' : null"
              :solo="player.playerProfileId && !player.playerPhone && !isOnFile('playerPhone')"
            ></v-text-field>
            <v-text-field
              v-else
              key="NonIntPhone2"
              name="playerPhone"
              :label="$v.player.playerPhone.hasOwnProperty('required') ? 'Player\'s Mobile Phone*' : 'Player\'s Mobile Phone'"
              v-model="player.playerPhone"
              :rules="$v.player.playerPhone.hasOwnProperty('required') && $v.player.playerPhone.$dirty ? [
                () => $v.player.playerPhone.required || 'A mobile phone number is required',
                () => isInternational || $v.player.playerPhone.ValidUSPhone || 'A valid mobile phone number is required',
              ] : []"
              @blur="$v.player.playerPhone.$touch(); $emit('blur')"
              color="color3"
              :disabled="isOnFile('playerPhone')"
              :placeholder="isOnFile('playerPhone') ? 'On File' : null"
              :solo="player.playerProfileId && !player.playerPhone && !isOnFile('playerPhone')"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" v-if="_fields.includes('playerEmail') || _fields.includes('*')">
            <v-text-field
              name="playerEmail"
              :label="$v.player.playerEmail.hasOwnProperty('required') ? 'Player\'sEmail*' : 'Player\'sEmail'"
              v-model="player.playerEmail"
              :rules="$v.player.playerEmail.hasOwnProperty('required') && $v.player.playerEmail.$dirty ? [
                () => $v.player.playerEmail.required || 'An email is required',
                () => $v.player.playerEmail.email || 'A valid email is required'
              ] : []"
              @blur="$v.player.playerEmail.$touch(); $emit('blur')"
              color="color3"
              :disabled="isOnFile('playerEmail')"
              :placeholder="isOnFile('playerEmail') ? 'On File' : null"
              :solo="player.playerProfileId && !player.playerEmail && !isOnFile('playerEmail')"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- PLAYER Parent -->
        <v-expand-transition>
          <v-row dense v-if="showParent || _fields.includes('emergency')" :key="`row-${player.age}`">
            <v-col cols="12" v-if="showParent" :key="`col-${player.age}`">
                <person-form
                  ref="p3"
                  :player="player.parent"
                  :disable="isOnFile('parent')"
                  :validate="true"
                  @valid-change="onValidChangeParent"
                  :fields="['name']"
                  :requiredFields="['phone', 'email']"
                  :embed="true"
                  :onFile="isOnFile('parent')"
                  :solo="!!player.playerProfileId"
                ></person-form>
            </v-col>
            <v-col cols="12" v-if="_fields.includes('emergency')">
                <person-form
                  ref="p4"
                  :player="player.emergency"
                  :disable="isOnFile('emergency')"
                  @valid-change="onValidChangeEmergency"
                  :fields="['name']"
                  :requiredFields="['phone', 'email']"
                  :onFile="isOnFile('emergency')"
                  :solo="!!player.playerProfileId"
                ></person-form>
            </v-col>
          </v-row>
        </v-expand-transition>
        <v-row dense v-if="showAauVerify">
          <v-col cols="12" sm="6" md="4">
            <strong class="red--text">You must verify the AAU # above before continuing.</strong>
          </v-col>
        </v-row>
        <template v-if="askForPartner">
          <v-divider class="my-4"></v-divider>
          <!-- Partner -->
          <v-row dense>
            <v-col cols="12" class="title">
              Partner
              <div class="subheading mt-1">If you have a partner for this tournament, enter their name below</div>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Partner"
                v-model="registration.partner"
                :solo="!!player.playerProfileId"
              ></v-text-field>
            </v-col>
          </v-row>
        </template>
        <template v-if="isCoaches && coachProps && coachProps.team">
          <v-divider class="my-4"></v-divider>
          <!-- Team -->
          <v-row dense>
            <v-col cols="12" class="title">
              Team
              <div class="subheading mt-1">Please select the team(s) you will be coaching</div>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                color="color3"
                item-color="color3"
                :items="tournament.teams.map(m => { return {id: m.id, name: m.name}})"
                v-model="registration.teams"
                chips deletable-chips
                item-text="name"
                item-value="id"
                return-object
                solo multiple
              ></v-autocomplete>
            </v-col>
          </v-row>
        </template>
      </div>
    </v-expand-transition>
    <v-row dense v-if="showReplaceable" class="py-0">
      <v-col cols="12" class="py-0 text-right">
        <replaceable-dialog
          :tournament="tournament"
          :registration="registration"
          :playerI="playerI"
          :adminQuickAdd="adminQuickAdd"
          :playerString="playerString"
        ></replaceable-dialog>
      </v-col>
    </v-row>
    <validation-drawer
      ref="validator"
      :body="selectedSb"
      :player="player"
      :chipColor="chipColor"
      :minLevel="minLevel"
      :tournament="tournamentX"
    ></validation-drawer>
  </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email, sameAs, integer } from 'vuelidate/lib/validators'
import AAUValidator from '@/components/Tournament/Registration/New/AAUValidator.vue'
import AvpaValidator from '@/components/Tournament/Registration/New/AvpaValidator'
import PlayerLookup from './PlayerLookup'
import TbdDialog from './TbdDialog'
import ReplaceableDialog from './ReplaceableDialog'
import moment from 'moment'
import Heights from '@/classes/Heights'
import IBVL from '@/classes/IBVL'
import { mask } from 'vue-the-mask'
import ValidUSPhone from '@/helpers/ValidUSPhone'
import { ageDayOf, indoorAgeDivisionAge, ageDivisionAge, aauAgeDivisionAge } from '@/classes/ProfileHelpers'
import { mapGetters } from 'vuex'
import PersonForm from '@/components/Forms/PersonForm'
import MembershipCreateDialog from './MembershipCreateDialog'
import { getHsp } from '@/classes/Dark'
import * as actions from '@/store/ActionTypes'
import AVP from '@/classes/AvpSanctioning'
const TwoFactorPhone = () => import('@/components/Forms/TwoFactorPhone.vue')
const CommittedFormFields = () => import('@/components/Forms/CommittedFormFields.vue')
const AdditionalField = () => import('@/components/Tournament/Registration/New/AdditionalField')
const ValidationDrawer = () => import('@/components/Tournament/Registration/New/ValidationDrawer')

export default {
  directives: { mask },
  props: ['tournament', 'registration', 'player', 'adminQuickAdd', 'playerI', 'active', 'partners', 'edit', 'international', 'participantType', 'mode', 'allowZeroPlayers', 'noTbd', 'xPlayers'],
  mixins: [validationMixin],
  validations () {
    return {
      player: {
        firstName: { required },
        lastName: { required },
        phone: this.enforceV && !this.skipRequired('phone') && this.requiredFields.includes('phone') && !this.isOnFile('phone') ? !this.isInternational ? { required, ValidUSPhone } : { required } : {},
        email: this.enforceV && !this.skipRequired('email') && (this.requiredFields.includes('email') || this.requiredFields.includes('emailConfirm')) && !this.isOnFile('email') ? { required, email } : {},
        address: this.enforceV && !this.skipRequired('address') && this.requiredFields.includes('fullAddress') && !this.isOnFile('address') ? { required } : {},
        city: this.enforceV && !this.skipRequired('city') && (this.requiredFields.includes('fullAddress') || this.requiredFields.includes('cityState')) && !this.isOnFile('city') ? { required } : {},
        state: this.enforceV && !this.skipRequired('state') && (this.requiredFields.includes('fullAddress') || this.requiredFields.includes('cityState')) && !this.isOnFile('state') ? { required } : {},
        zip: this.enforceV && !this.skipRequired('zip') && (this.requiredFields.includes('fullAddress') || this.requiredFields.includes('zip')) && !this.isOnFile('zip') && !this.international ? { required } : {},
        country: this.enforceV && !this.skipRequired('country') && this.requiredFields.includes('country') && !this.isOnFile('country') ? { required } : {},
        dob: this.enforceV && !this.skipRequired('dob') && this.requiredFields.includes('dob') && (!this.isOnFile('dob') || this.tooOldOnFile) ? { required } : {},
        club: this.enforceV && !this.skipRequired('club') && this.requiredFields.includes('club') && !this.isOnFile('club') ? { required } : {},
        aauNumber: this.enforceV && !this.skipRequired('aau') && (this.requiredFields.includes('aau') || this.isAAU) && !this.isOnFile('aauNumber') && !this.international && !(this.isAAU && this.requiredFields.includes('noSanctionRequirement')) ? { required } : {},
        p1440Number: this.enforceV && !this.skipRequired('p1440') && (this.requiredFields.includes('p1440') || this.isP1440) && !this.p1440OnFile && !this.sbMemInCart && !(this.isP1440 && this.requiredFields.includes('noSanctionRequirement')) ? { required } : {},
        avpNumber: this.enforceV && !this.skipRequired('avp') && (this.requiredFields.includes('avp') || this.isAVP) && !this.isOnFile('avpNumber') && !this.sbMemInCart && !(this.isAVP && this.requiredFields.includes('noSanctionRequirement')) ? { required } : {},
        usavNumber: this.enforceV && !this.skipRequired('usav') && (this.requiredFields.includes('usav') || this.isUSAV) && !this.isOnFile('usavNumber') && !(this.isUSAV && this.requiredFields.includes('noSanctionRequirement')) ? { required } : {},
        cbvaNumber: this.enforceV && !this.skipRequired('cbva') && this.requiredFields.includes('cbva') && !this.isOnFile('cbva') ? { required } : {},
        gradYear: this.enforceV && !this.skipRequired('gradYear') && this.requiredFields.includes('gradyear') && !this.isOnFile('gradYear') ? { required } : {},
        highSchool: this.enforceV &&
          !this.skipRequired('highSchool') &&
          (
            (
              (this.requiredFields.includes('highSchool') || this.requiredFields.includes('ibvl')) &&
              !this.isOnFile('highSchool')
            )
          ) ? { required } : {},
        shirtSize: this.enforceV && !this.skipRequired('shirtSize') && this.requiredFields.includes('shirtSize') && !this.isOnFile('shirtSize') ? { required } : {},
        height: this.enforceV && !this.skipRequired('height') && this.requiredFields.includes('height') && !this.isOnFile('height') ? { required } : {},
        playerPhone: this.enforceV && !this.skipRequired('playerPhone') && this.requiredFields.includes('playerPhone') && !this.isOnFile('playerPhone') ? !this.isInternational ? { required, ValidUSPhone } : { required } : {},
        playerEmail: this.enforceV && !this.skipRequired('playerEmail') && this.requiredFields.includes('playerEmail') && !this.isOnFile('playerEmail') ? { required, email } : {},
        clubDirectorName: this.enforceV && !this.skipRequired('clubDirectorName') && this.requiredFields.includes('clubDirectorName') && !this.isOnFile('clubDirectorName') ? { required } : {},
        clubDirectorEmail: this.enforceV && !this.skipRequired('clubDirectorEmail') && this.requiredFields.includes('clubDirectorEmail') && !this.isOnFile('clubDirectorEmail') ? { required, email } : {},
        sandRecruitsNumber: this.enforceV && !this.skipRequired('sandRecruitsNumber') && this.requiredFields.includes('sandRecruitsNumber') && !this.isOnFile('sandRecruitsNumber') ? { required } : {},
        _committed: this.enforceV && !this.skipRequired('_committed') && this.requiredFields.includes('committed') && !this.isOnFile('committed') ? { required } : {},
        committedSchool: this.enforceV && !this.skipRequired('_committed') && this.requiredFields.includes('committed') && !this.isOnFile('committed') && this.player.committed ? { required } : {},
        classification: this.enforceV && !this.skipRequired('classification') && this.requiredFields.includes('classification') && !this.isOnFile('classification') ? { required } : {},
        yearsExp: this.enforceV && !this.skipRequired('yearsExp') && this.requiredFields.includes('yearsExp') && !this.isOnFile('yearsExp') ? { required, integer } : {},
        hand: this.enforceV && !this.skipRequired('hand') && this.requiredFields.includes('hand') && !this.isOnFile('hand') ? { required } : {},
        side: this.enforceV && !this.skipRequired('side') && this.requiredFields.includes('side') && !this.isOnFile('side') ? { required } : {},
        instagram: this.enforceV && !this.skipRequired('instagram') && this.requiredFields.includes('instagram') && !this.isOnFile('instagram') ? { required } : {},
        male: this.enforceV && !this.skipRequired('gender') && this.requiredFields.includes('gender') && !this.isOnFile('gender') ? { required } : {},
        gpa: this.enforceV && !this.skipRequired('gpa') && this.requiredFields.includes('gpa') && !this.isOnFile('gpa') ? { required } : {}
      },
      emailConfirm: this.enforceV && !this.skipRequired('emailConfirm') && this.requiredFields.includes('emailConfirm') && !this.isOnFile('email') ? { required, sameAsEmail: sameAs(function () { return this.player.email }) } : {}
    }
  },
  data () {
    return {
      states: [],
      loading: false,
      dobPicker: false,
      dob: null,
      attendHS: false,
      manual: false,
      showSearch: false,
      dupDialog: false,
      ibvlBase: IBVL,
      countries: [],
      parentValid: false,
      emergencyValid: false,
      emailConfirm: null,
      verifiedPhone: null,
      verifiedPlayer: null,
      additionalFieldsValidation: [],
      selectedSb: null,
      iAgeGroup: 0,
      levelCheck: false,
      avpError: null,
      avpErrorDialog: false,
      playerLevels: []
    }
  },
  computed: {
    ...mapGetters(['user', 'getCart', 'theme', 'collegeList']),
    additionalFields () {
      if (this.registration && this.registration.division && this.tournament) {
        const fields = this.tournament.getCustomFields(this.registration.division.id)
        return fields ? fields.filter(f => f.perPlayer) : []
      }
      return []
    },
    additionalFieldsValid () {
      return this.additionalFieldsValidation.length === this.additionalFields.length
    },
    errors () {
      return {
        // eslint-disable-next-line no-prototype-builtins
        firstName: this.enforceV && 'required' in this.$v.player.firstName && this.$v.player.firstName.$dirty && !this.$v.player.firstName.required ? ['A first name is required'] : [],
        lastName: this.enforceV && 'required' in this.$v.player.lastName && this.$v.player.lastName.$dirty && !this.$v.player.lastName.required ? ['A last name is required'] : [],
        country: this.enforceV && 'required' in this.$v.player.country && this.$v.player.country.$dirty && !this.$v.player.country.required ? ['A country is required'] : [],
        phone: this.enforceV && 'required' in this.$v.player.phone && this.$v.player.phone.$dirty && !(this.$v.player.phone.required && (this.$v.player.phone.ValidUSPhone || this.international)) ? ['A valid mobile phone is required'] : [],
        email: this.enforceV && 'required' in this.$v.player.email && this.$v.player.email.$dirty && !this.$v.player.email.required ? ['An email is required'] : [],
        address: this.enforceV && 'required' in this.$v.player.address && this.$v.player.address.$dirty && !this.$v.player.address.required ? ['An address is required'] : [],
        city: this.enforceV && 'required' in this.$v.player.city && this.$v.player.city.$dirty && !this.$v.player.city.required ? ['A city is required'] : [],
        state: this.enforceV && 'required' in this.$v.player.state && this.$v.player.state.$dirty && !this.$v.player.state.required ? ['A State/Province/Region is required'] : [],
        zip: this.enforceV && 'required' in this.$v.player.zip && this.$v.player.zip.$dirty && !this.$v.player.zip.required ? ['A zip is required'] : [],
        dob: this.enforceV && 'required' in this.$v.player.dob && this.$v.player.dob.$dirty && !this.$v.player.dob.required ? ['A dob is required'] : [],
        club: this.enforceV && 'required' in this.$v.player.club && this.$v.player.club.$dirty && !this.$v.player.club.required ? ['A club is required'] : [],
        gradYear: this.enforceV && 'required' in this.$v.player.gradYear && this.$v.player.gradYear.$dirty && !this.$v.player.gradYear.required ? ['A graduation year is required'] : [],
        highSchool: this.enforceV && 'required' in this.$v.player.highSchool && this.$v.player.highSchool.$dirty && !this.$v.player.highSchool.required ? ['A school is required'] : [],
        aauNumber: this.enforceV && 'required' in this.$v.player.aauNumber && this.$v.player.aauNumber.$dirty && !this.$v.player.aauNumber.required ? ['An AAU number is required'] : [],
        avpNumber: this.enforceV && 'required' in this.$v.player.avpNumber && this.$v.player.avpNumber.$dirty && !this.$v.player.avpNumber.required ? ['An AVP number is required'] : [],
        shirtSize: this.enforceV && 'required' in this.$v.player.shirtSize && this.$v.player.shirtSize.$dirty && !this.$v.player.shirtSize.required ? ['A shirtSize is required'] : [],
        height: this.enforceV && 'required' in this.$v.player.height && this.$v.player.height.$dirty && !this.$v.player.height.required ? ['A height is required'] : [],
        clubDirectorName: this.enforceV && 'required' in this.$v.player.clubDirectorName && this.$v.player.clubDirectorName.$dirty && !this.$v.player.clubDirectorName.required ? ['A club director name is required'] : [],
        clubDirectorEmail: this.enforceV && 'required' in this.$v.player.clubDirectorEmail && this.$v.player.clubDirectorEmail.$dirty && !this.$v.player.clubDirectorEmail.required ? ['A club director email is required'] : [],
        _committed: this.enforceV && 'required' in this.$v.player._committed && this.$v.player._committed.$dirty && !this.$v.player._committed.required ? ['Yes or No is required'] : [],
        committedSchool: this.enforceV && 'required' in this.$v.player.committedSchool && this.$v.player.committedSchool.$dirty && !this.$v.player.committedSchool.required ? ['A school is required'] : []
      }
    },
    allowTbd () {
      return this.tournament.props.includes('allowTbd') || this.allowZeroPlayers || (this.registration.window && this.registration.window.allowTbd)
    },
    emailMatch () {
      return this.player.email === this.emailConfirm
    },
    chipColor () {
      const hsp1 = getHsp(this.theme.color1)
      const hsp2 = getHsp(this.theme.color1Text)
      return hsp1 < hsp2 ? 'color1 color1Text--text' : 'color1Text color1--text'
    },
    oneCart () {
      return this.tournament.props.includes('oneCart')
    },
    cart () {
      return this.getCart(this.registration.organization.id)
    },
    cartMems () {
      if (!this.oneCart) return []
      const a = this.cart && this.cart.items.filter(f => f.type === 'MEM').map(m => {
        const p = JSON.parse(m.product)
        return {
          linkId: m.linkId,
          profile: p.profile,
          org: p.organization
        }
      })
      return a ? a.filter(f => this.sanctioningBody.toLowerCase().startsWith(f.org.username)) : []
    },
    regVblIds () {
      return this.registration.players.map(m => m.vblId).filter(f => !!f)
    },
    cartMemsAvail () {
      return this.cartMems && this.cartMems.filter(f => !this.regVblIds.includes(f.linkId))
    },
    playerCartMem () {
      return this.cartMems.length > 0 && this.player.vblId && this.cartMems.find(f => f.linkId === this.player.vblId)
    },
    sbMemInCart () {
      return this.playerCartMem && this.sanctioningBody.toLowerCase().startsWith(this.playerCartMem.org.username)
    },
    pGender () {
      return this.player && this.player.gender
    },
    joinNow () {
      if (this.tournament.isBVNE) return 'https://bvne.volleyballlife.com/join'
      if (this.tournament.isP1440) return 'https://play.p1440.org/join'
      return `https://${this.tournament.membershipUsername}.volleyballlife.com/join`
    },
    elite () {
      return this.sanctioningBody.toLowerCase().includes('elite') && !this.tournament.eliteOverride
    },
    isAAU () {
      return this.tournament.allSanctionBodies.includes('AAU') && !this.isCollegeCoaches && !this.isSpectators
    },
    isAVP () {
      return this.tournament.allSanctionBodies.includes('AVPA') && !this.isCollegeCoaches && !this.isSpectators
    },
    isP1440 () {
      return this.tournament.allSanctionBodies.includes('p1440') && !this.isCollegeCoaches && !this.isSpectators
    },
    isUSAV () {
      return this.tournament.allSanctionBodies.includes('USAV') && !this.isCollegeCoaches && !this.isSpectators
    },
    isSanctioned () {
      return this.isAAU || this.isAVP || this.isP1440 || this.isUSAV
    },
    askForPartner () {
      return this.individual && this.tournament.props.includes('partner') && !this.isCoaches
    },
    individual () {
      return this.registration && this.registration.division && this.registration.division.numOfPlayers === 1 && this.registration.division.numAllowedOnRoster === 1
    },
    shirtSizes () {
      let sizes = ['XS', 'S', 'M', 'L', 'XL', '2X']
      const exclude = this.tournament.props.filter(f => f.toUpperCase().startsWith('NO_'))
      exclude.forEach(x => {
        const a = x.replace('NO_', '')
        sizes = sizes.filter(f => f !== a)
      })

      return sizes
    },
    tooOldOnFile () {
      if (this.ageDivision === 0 || this._fields.includes('dob-gy')) return false
      if (this._fields.includes('usavDob')) {
        return this.player && this.player.searchResult && this.player.searchResult.dob && indoorAgeDivisionAge(this.player.searchResult.dob, this.tournament.season) > this.ageDivision
      }
      if (this._fields.includes('aauDob')) {
        return this.player && this.player.searchResult && this.player.searchResult.dob && aauAgeDivisionAge(this.player.searchResult.dob, this.tournament.season) > this.ageDivision
      }
      if (this._fields.includes('dayOf')) {
        return this.player && this.player.searchResult && this.player.searchResult.dob && ageDayOf(this.player.searchResult.dob, this.tournament.startDate) > this.ageDivision
      }
      return this.player && this.player.searchResult && this.player.searchResult.dob && ageDivisionAge(this.player.searchResult.dob, this.tournament.season) > this.ageDivision
    },
    partnerList () {
      return this.playerI > 1 ? this.partners : []
    },
    enforceV () {
      if (this.player && this.player.playerProfileId === 2764) return false
      return !this.adminQuickAdd
    },
    p1Only () {
      return this.division.props.includes('p1Only')
    },
    season () {
      return this.tournament.season
    },
    division () {
      return this.registration.division
    },
    isBidPlayer () {
      return this.registration.bid && this.registration.bid.players.map(m => m.id).includes(this.player.playerProfileId)
    },
    showReplaceable () {
      return this.isBidPlayer && this.registration.bid.players.length > this.registration.bid.subPolicy
    },
    notReplaceable () {
      return this.registration.notReplaceable
    },
    replaceable () {
      return !this.player.playerProfileId || !this.registration.notReplaceable.includes(this.player.playerProfileId)
    },
    playerString () {
      return `player ${this.playerI}`
    },
    showFields () {
      return this.manual || !!this.player.playerProfileId || this.edit
    },
    fields () {
      const a = this.division && this.division.registrationFields ? this.division.registrationFields.fields : []
      return (this.division && this.division.gender && this.division.gender.name !== 'Coed') ? a.filter(f => f !== 'gender') : a
    },
    requiredFields () {
      const a = this.division && this.division.registrationFields ? this.division.registrationFields.requiredFields : []
      return (this.division && this.division.gender && this.division.gender.name !== 'Coed') ? a.filter(f => f !== 'gender') : a
    },
    sanctioningBodyId () {
      return this.division && this.division.sanctioningBodyId
    },
    gender () {
      return this.division.gender.name
    },
    ageDivision () {
      return this.division && this.division.division ? +this.division.division.name.replace(/\D/g, '') : 0
    },
    schoolBlock () {
      return this.playerI > 1 && this.division && this.division.registrationFields && this.division.registrationFields.requiredFields.includes('sameSchool')
    },
    age2 () {
      if (this.season && this.player && this.player.dob) {
        const dob = moment(this.player.dob)
        const seasonStart = moment(`${this.season}-08-31`)
        return seasonStart.diff(dob, 'years')
      }
      return null
    },
    isfAge () {
      const dob = moment(this.player.dob)
      switch (this.season - dob.year()) {
        case 18:
          return 18
        case 17:
          return 18
        case 16:
          return 18
        case 15:
          return 15
        case 14:
          return 15
        case 13:
          return 15
        default:
          return this.season - dob.year()
      }
    },
    ageDayOf () {
      return this.player.dob && this.tournament.startDate && ageDayOf(this.player.dob, this.tournament.startDate)
    },
    indoorAgeDivisionAge () {
      return this.player.dob && this.tournament.season && indoorAgeDivisionAge(this.player.dob, this.tournament.season)
    },
    aauAgeDivisionAge () {
      return this.player.dob && this.tournament.season && aauAgeDivisionAge(this.player.dob, this.tournament.season)
    },
    dobOnFile () {
      if (this.edit && !this.player.searchResult) return !this.player.dob
      return this.isOnFile('dob') && !this.tooOldOnFile
    },
    dobErrors () {
      const errors = []
      if (this.requiredFields.includes('dob')) {
        if (!this.$v.player.dob.$dirty) return errors
        !this.$v.player.dob.required && errors.push('A birthdate is required')
        !this.validDob && this.validateHS && !this.attendHS && errors.push('The athlete must be a high school student')
        !this.validDob && errors.push(`The athlete is too old for ${this.division.division.name}`)
      }
      return errors
    },
    gradYearErrors () {
      const errors = []
      if (this.requiredFields.includes('gradyear')) {
        if (!this.$v.player.gradYear.$dirty) return errors
        !this.$v.player.gradYear.required && errors.push('A grad year is required')
        !this.validGradYear && errors.push(`The athlete is too old for ${this.division.division.name}`)
      }
      return errors
    },
    valid () { return !this.$v.$invalid },
    _fields () {
      return this.fields.concat(this.requiredFields)
    },
    p1440OnFile () {
      return this.elite ? this.isOnFile('p1440Elite') : this.isOnFile('p1440Number')
    },
    verified () {
      if (!this.sanctioningBody || this.isCollegeCoaches || this.isSpectators) return true
      if (this.requiredFields.includes('noSanctionRequirement')) return true
      if (this.sbMemInCart) return true

      return this.avpVerified && this.aauVerified && this.p1440Verified && this.usavVerified
    },
    aauVerified () {
      if (this.skipRequired('aau')) return true
      if (this.isAAU || this.requiredFields.includes('aau')) {
        return this.isOnFile('aauNumber') || this.aauIsValid || this.international
      }
      return true
    },
    aauIsValid () {
      return this.player.manualAau || this.player.validAau
    },
    avpVerified () {
      if (this.skipRequired('avp')) return true
      if (this.isAVP || this.requiredFields.includes('avp')) {
        return this.isOnFile('avpNumber') || this.avpIsValid
      }
      return true
    },
    avpIsValid () {
      if (this.levelCheck) return false
      if (this.player.avpaStatus === 'true') return true
      if (this.requiredFields.includes('avp') || this.isAVP) return false
      return this._fields.includes('avp') ? this.player.manualAvp : true
    },
    p1440Verified () {
      if (this.skipRequired('p1440')) return true
      if (this.isP1440 || this.requiredFields.includes('p1440')) {
        return this.p1440OnFile || this.p1440IsValid
      }
      return true
    },
    p1440IsValid () {
      return this.player.p1440Status === 'true'
    },
    usavVerified () {
      if (this.skipRequired('usav')) return true
      if (this.isUSAV || this.requiredFields.includes('usav')) {
        return this.isOnFile('usavNumber') || this.usavIsValid
      }
      return true
    },
    usavIsValid () {
      return this.player.usavStatus === 'true' || this.isInternational
    },
    validDob () {
      if (!this.requiredFields.includes('dob') || !this.player || !this.player.dob) return true
      if (this.ageDivision === 0) return true
      if (this._fields.includes('dob-gy')) return this.validGradYear
      if (this._fields.includes('dobv')) return this.ageDivision === 18 ? this.valid18U : this.age2 <= this.ageDivision
      if (this._fields.includes('isf')) return this.isfAge <= this.ageDivision
      if (this._fields.includes('dayOf')) return this.ageDayOf <= this.ageDivision
      if (this._fields.includes('usavDob')) return this.ageDivision === 18 ? this.valid18U : this.indoorAgeDivisionAge <= this.ageDivision
      if (this._fields.includes('aauDob')) return this.ageDivision === 18 ? this.valid18U : this.aauAgeDivisionAge <= this.ageDivision
      return true
    },
    validGradYear () {
      if (this.ageDivision === 0 || !this._fields.includes('dob-gy')) return true
      if (!this.player.gradYear) return false
      var diff = 18 - this.ageDivision
      var gy = this.season + diff
      return this.player.gradYear >= gy
    },
    validateHS () {
      if (this.ageDivision === 18) {
        return (this._fields.includes('dobv') && this.age2 === 19) ||
        (this._fields.includes('usavDob') && this.indoorAgeDivisionAge === 19) ||
        (this._fields.includes('aauDob') && this.aauAgeDivisionAge === 19)
      }
      return false
    },
    valid18U () {
      if (this._fields.includes('dobv')) return this.age2 < 19 || (this.age2 === 19 && this.attendHS)
      if (this._fields.includes('usavDob')) return this.indoorAgeDivisionAge < 19 || (this.indoorAgeDivisionAge === 19 && this.attendHS)
      if (this._fields.includes('aauDob')) return this.aauAgeDivisionAge < 19 || (this.aauAgeDivisionAge === 19 && this.attendHS)
      return this.age2 < 19 || (this.age2 === 19 && this.attendHS)
    },
    sanctioningBody () {
      return this.sanctioningBodyId ? this.sanctioningBodyId.toLowerCase().startsWith('avp') ? 'AVP' : this.sanctioningBodyId : ''
    },
    gradYears () {
      return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => {
        return i + new Date().getFullYear()
      })
    },
    showParent () {
      const a = this._fields.includes('parent')
      const b = this.player.age > 0 && this.player.age < 18
      const c = this.registration && this.registration.division && this.registration.division.ageType.id === 1
      const d = this.iAgeGroup > 0 && this.iAgeGroup < 20

      return a && (c || b || d)
    },
    showAauVerify () {
      return this.isAAU && this.player.aauNumber && this.player.lastName && !this.aauIsValid && !this.verified
    },
    ibvl () {
      return this.gender.toLowerCase() === 'boys' ? this.ibvlBase.boys : this.ibvlBase.girls
    },
    heightOptions () {
      return Heights
    },
    isCoaches () {
      return this.registration && this.registration.division && this.registration.division.isCoaches
    },
    isCollegeCoaches () {
      return this.registration && this.registration.division && this.registration.division.isCollegeCoaches
    },
    isSpectators () {
      return this.registration && this.registration.division && this.registration.division.isSpectators
    },
    coachProps () {
      return this.registration && this.registration.division && this.registration.division.coachProps
    },
    validCoach () {
      if (this.isCoaches) {
        if (this.coachProps && this.coachProps.team) return !!this.registration.teams.length
      }

      return true
    },
    validParent () {
      if (this.requiredFields.includes('parent') && !this.isOnFile('parent') && this.player.age < 18) {
        return this.parentValid
      }

      return true
    },
    validEmergency () {
      if (this.requiredFields.includes('emergency') && !this.isOnFile('emergency')) {
        return this.emergencyValid
      }

      return true
    },
    isInternational () {
      return this.player && this.player.country && this.player.country !== 'United States'
    },
    checkPhone () {
      return this.playerI === 1 && this.requiredFields.includes('2FPhone') && !this.$v.player.phone.$invalid && this.player.phone && this.player.phone.replace(/\D/g, '').length === 10
    },
    twoFactorPhone () {
      return this.player.phone && this.player.phone.replace(/\D/g, '') === this.verifiedPhone
    },
    twoFactorPlayer () {
      return this.player.playerProfileId && this.player.playerProfileId === this.verifiedPlayer
    },
    twoFactorPass () {
      return !this.requiredFields.includes('2FPhone') || this.twoFactorPhone || this.twoFactorPlayer || this.playerI > 1
    },
    phoneErrors () {
      const errors = []
      if (!this.twoFactorPass) {
        errors.push('Two factor authentication is required')
        return errors
      }
      if (!this.$v.player.phone.$dirty) return errors
      !this.$v.player.phone.required && errors.push('A mobile phone number is required')
      !this.isInternational && !this.$v.player.phone.ValidUSPhone && errors.push('Your password must be at least 6 characters')
      return errors
    },
    minLevel () {
      if (this.selectedSb && this.selectedSb.toLowerCase() === 'avpa') return this.tournament.avpMembership

      return null
    }
  },
  methods: {
    validate (sb) {
      this.selectedSb = sb
      this.$refs.validator.open()
    },
    on2FVerified (v) {
      this.verifiedPhone = v
    },
    on2FVerifiedPlayer (v) {
      this.verifiedPlayer = v
    },
    onValidChangeParent (v) {
      this.parentValid = v
      this.setValid()
    },
    onValidChangeEmergency (v) {
      this.emergencyValid = v
      this.setValid()
    },
    onTbdClick () {
      this.$emit('tbd-click')
    },
    getCountries () {
      if (this.countries.length) return
      this.loading = true
      this.$VBL.get.countries()
        .then(r => {
          this.countries = r.data
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    },
    onManualClick (name) {
      const names = name.split(' ')
      this.player.lastName = names.pop()
      this.player.firstName = names.join(' ')
      this.manual = true
    },
    isOnFile (field) {
      if (this.isCollegeCoaches && field === 'highSchool') return false
      const prop = `force-${field}`
      if (this.tournament.props.includes(prop)) return false
      if (this.registration && this.registration.division && this.registration.division.props.includes(prop)) return false
      return this.player && (this.player.playerProfileId === 2764 || this.player.isOnFile(field))
    },
    skipRequired (field) {
      if (field === 'usav' && this.isInternational) return true
      const sbFields = ['aau', 'avp', 'p1440', 'usav']
      if (sbFields.includes(field)) {
        if (this.requiredFields.includes('noSanctionRequirement')) return true

        const over = this.tournament.membershipLevelsOverrideSanction
        if (this.tournament.membersOnly && over) {
          const goOverride = this.playerLevels.findIndex(f => over.includes(f)) > -1
          return goOverride
        }
      }

      return this.playerI > 1 && this.division.props.includes('p1Only') && !this.division.props.includes(field)
    },
    setDob () {
      if (!this.dob || this.dob.length !== 8) {
        this.player.dob = null
      } else {
        const month = this.dob.substring(0, 2)
        const day = this.dob.substring(2, 4)
        const year = this.dob.substring(4, 8)
        this.player.dob = `${year}-${month}-${day}`
      }
      this.$v.player.dob.$touch()
    },
    onAdditionValidChange (v) {
      if (v.valid) {
        if (!this.additionalFieldsValidation.includes(v.i)) this.additionalFieldsValidation.push(v.i)
      } else {
        this.additionalFieldsValidation = this.additionalFieldsValidation.filter(f => f !== v.i)
      }
      this.setValid()
    },
    setValid () {
      if (!this.enforceV) {
        this.player.valid = true
        return
      }
      const r = this.division.registrationFields.requiredFields
      const rDob = this.isSanctioned || (this._fields.includes('parent') && this.registration && this.registration.division && this.registration.division.ageType.id !== 1)
      if (rDob && !r.includes('dob')) {
        r.push('dob')
      }
      if (r.includes('dob-gy') && !r.includes('gradyear') && this.ageDivision !== 0) {
        r.push('gradyear')
      }
      this.player.valid = !this.$v.$invalid && ((this.verified && this.validDob && this.validGradYear && this.validCoach && this.validEmergency && this.validParent && this.twoFactorPass && this.additionalFieldsValid) || !this.enforceV)
    },
    heightFocus () {
      if (!this.player.height) {
        this.player.height = '5\' 7"'
        setTimeout(() => {
          this.player.height = null
        }, 50)
      }
    },
    onPlayerSelected (val) {
      this.player.setProfile(val)
      const over = this.tournament.membershipLevelsOverrideSanction
      if (over && over.length && val.levels) {
        this.playerLevels = val.levels
      }
      if (this.tournament.checkMembership) {
        this.player.checkMemberships()
      }
      this.iAgeGroup = val.ageGroup
      this.$v.$touch()
      this.$emit('player-selected')
      this.showSearch = false
      this.$nextTick(() => {
        if (!this.validGradYear) {
          this.player.reAskGradYear()
          this.$v.player.gradYear.$touch()
        }
      })
      if (this.tournament.avpMembership && this.isOnFile('avpNumber')) {
        this.checkAvpMembership(val.id)
      }
    },
    checkAvpMembership (id) {
      this.levelCheck = true
      this.$VBL.getLevel(id)
        .then(r => {
          const l = AVP.memberships.find(f => f.value === r.data.level.toUpperCase())
          const t = this.tournament.avpMembershipObj
          if (l.n < t.n) {
            this.player.removeOnFile('avpNumber')
            this.player.avpaStatus = null
            this.avpError = t.e
            this.avpErrorDialog = true
          }
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.levelCheck = false
        })
    },
    resetPlayer () {
      this.player.init()
      this.showSearch = true
    },
    getStates () {
      if (this.states.length) return
      this.loading = true
      this.$http.get(`${window.location.origin}/static/json/states.json`)
        .then(r => {
          this.states = r.data
        })
        .catch(e => { console.log(e.response) })
        .finally(() => { this.loading = false })
    },
    useCartMem (val) {
      this.player.update(val.profile)
      this.setValid()
      this.$emit('player-selected')
      this.showSearch = false
    }
  },
  watch: {
    active: 'setValid',
    '$v.$invalid': 'setValid',
    verified: 'setValid',
    validDob: 'setValid',
    enforceV: 'setValid',
    validCoach: 'setValid',
    validGradYear: 'setValid',
    twoFactorPass: 'setValid',
    playerLevels: 'setValid',
    checkPhone: function (v) {
      if (v) {
        this.$refs.twoFactor.checkNumber()
      }
    },
    dobPicker: function (val) {
      val && this.$nextTick(() => (this.$refs.dobPickerPicker.activePicker = 'YEAR'))
      !val && this.$v.player.dob.$touch()
    },
    'player.key': function () {
      this.$v.$reset()
      this.setValid()
    },
    international: function (val) {
      if (val) {
        this.showSearch = false
        this.manual = true
      } else {
        this.showSearch = true
        this.manual = false
      }
    },
    isCollegeCoaches: function (val) {
      this.manual = val
    },
    isSpectators: function (val) {
      this.manual = val
    },
    showSearch: function (v) {
      if (v && !this.player.firstName && !this.player.lastName) this.manual = false
    }
  },
  mounted () {
    this.getStates()
    this.getCountries()
    this._fields.includes('committed') && this.$store.dispatch(actions.LOAD_COLLEGE_LIST)

    this.setValid()
  },
  components: {
    'aau-validator': AAUValidator,
    ReplaceableDialog,
    PlayerLookup,
    AvpaValidator,
    TbdDialog,
    PersonForm,
    MembershipCreateDialog,
    TwoFactorPhone,
    CommittedFormFields,
    AdditionalField,
    ValidationDrawer
  }
}
</script>

<style>

</style>
