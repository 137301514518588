import DtoUpdate from './DtoUpdate'
import moment from 'moment'
import firstBy from 'thenby'
import uniq from 'lodash.uniq'
import uniqBy from 'lodash.uniqby'
import toLower from 'lodash.tolower'
import { formatArray, fixed1OrInt, fixedX } from '@/Filters'
import flatten from '@/helpers/ArrayFlatten'
import TeamPlayer from './TeamPlayer'
import { isSuperset } from '@/helpers/SetFunctions'
import { average } from './HelperFunctions'

export default class Team {
  type = null
  _dto = null
  id = 0
  teamId = 0
  name = null
  seed = null
  finish = null
  registrationData = {}
  checkedIn = false
  players = []
  summary = null
  tags = []
  // private
  bracket = null
  bracketN = null
  starters = 2

  constructor (sdk, dto, type) {
    this.sdk = sdk
    this.type = type
    if (dto) {
      this.update(dto)
    }
  }

  update (dto) {
    if (typeof dto === 'string') dto = JSON.parse(dto)
    const exclude = ['players', 'registrationData']
    if (!dto.summary) exclude.push('summary')
    DtoUpdate(dto, this, exclude)

    if (dto.players) {
      this.players = dto.players.map(m => new TeamPlayer(m, this.tags))
    }
    if (this.players && this.players.length) {
      this.players = this.players.sort(firstBy('lastName'))
    }
    if (dto.registrationData) {
      DtoUpdate(dto.registrationData, this.registrationData)
    }
    this._dto = null
  }

  edit () {
    this._dto = JSON.stringify(this.dto)
  }

  restore () {
    if (this.isDirty()) this.update(this._dto)
    this._dto = null
  }

  isDirty () {
    return this._dto !== JSON.stringify(this.dto) || this.id === 0
  }

  getRep (f) {
    const repTag = this.tags.find(f => f.startsWith('repField:'))
    if (repTag) {
      return repTag.replace('repField:', '')
    }
    f = (f || 'club').toLowerCase()
    return f === 'committedschool' ? this.committedSchool : f === 'citystate' ? this.cityState : f === 'club' ? this.club : f === 'hs' ? this.highSchool : this.repping
  }

  hasBookmarkedPlayer (user) {
    var ids = this.players.map(m => m.ppId)
    return user && user.bookmarks && user.bookmarks.filter(f => ids.includes(f.id)).length > 0
  }

  // getters
  get playerNames () {
    return this.players && this.players.length ? this.players.map(m => m.name) : this.name ? this.name.split('/') : []
  }

  get nameIsPlayersNames () {
    if (!this.name.includes('/')) return false
    const a = new Set(this.playerNames)
    const b = new Set(this.name.split('/'))
    return isSuperset(a, b)
  }

  get playerNamesStr () {
    return this.playerNames.join('/')
  }

  get playerNamesCommit () {
    return this.players && this.players.length ? this.players.map(m => `${m.name} ${m.commitForName}`) : this.name ? this.name.split('/') : []
  }

  get firstNames () {
    return this.players && this.players.length && this.players.map(m => m.firstName)
  }

  get lastNames () {
    return this.players && this.players.length && this.players.map(m => m.lastName)
  }

  get cityState () {
    return this.concatProp('cityState')
  }

  get club () {
    return this.concatProp('club')
  }

  get gradYear () {
    return this.concatProp('gradYear')
  }

  get commitAbbr () {
    return this.concatProp('commitAbbr')
  }

  get committedSchool () {
    return this.concatProp('committedSchool')
  }

  get highSchool () {
    return this.concatProp('highSchool')
  }

  concatProp (prop) {
    const cs = this.players.filter(f => f[prop]).map(m => m[prop])
    if (cs.length) {
      const r = uniqBy(cs, toLower)
      return formatArray(r)
    }
    return null
  }

  set dtCreated (val) {
    this._dtCreated = val
  }

  get dtCreated () {
    return this._dtCreated ? moment(this._dtCreated) : null
  }

  get dirty () {
    return this._dto !== JSON.stringify(this.dto)
  }

  get dto () {
    return this.type === 'pool' ? {
      id: this.id,
      teamId: this.teamId,
      poolId: this.poolId,
      name: this.name,
      seed: this.seed || 0,
      slot: this.slot,
      finish: this.finish
    } : {
      id: this.id,
      teamId: this.teamId,
      bracketId: this.bracketId,
      name: this.name,
      seed: this.seed,
      finish: this.finish
    }
  }

  get seeded () {
    return !!this.seed
  }

  get iSeed () {
    return this.seed || 9999
  }

  get unix () {
    return +this.dtCreated
  }

  get primaryState () {
    const states = this.players.filter(f => f.state).map(p => p.state)
    const g = uniq(states).map(m => {
      return {
        state: m,
        count: states.filter(f => f === m).length,
        capt: this.players[0].state === m ? 1 : 0
      }
    }).sort(firstBy('count', -1).thenBy('capt', -1))
    return g.length ? g[0].state : ''
  }

  get points () {
    const result = []
    flatten(this.players.filter(f => !!f.points).map(p => p.points)).forEach(a => {
      const x = result.find(f => f.short === a.short)
      if (x) {
        x.total += a.total
      } else {
        result.push(Object.assign({}, a))
      }
    })
    return result.sort(firstBy('sanction'))
  }

  get seedPoints () {
    const result = []
    flatten(this.players.map(p => p.playerPoints)).forEach(a => {
      const x = result.find(f => f.systemId === a.systemId)
      if (x) {
        x.total += a.total
      } else {
        result.push(Object.assign({}, a))
      }
    })
    return result.sort(firstBy('system'))
  }

  getSeedPoints (id, zero) {
    let useId = true
    if (typeof id === 'string') {
      useId = false
    }
    const p = useId ? this.seedPoints.find(f => f.systemId === id) : this.seedPoints.find(f => f.system.toLowerCase() === id.toLowerCase())
    return p ? fixed1OrInt(p.total) : zero ? 0 : 'NA'
  }

  get aauPoints () {
    return this.getSeedPoints(3)
  }

  get bvnePoints () {
    return this.getSeedPoints(19)
  }

  get p1440Points () {
    return this.getSeedPoints(4)
  }

  get avpaPoints () {
    return this.getSeedPoints('USAVP')
  }

  get avpaCPoints () {
    return this.getSeedPoints(57)
  }

  get usavPoints () {
    return this.getSeedPoints('USAVP')
  }

  get movr () {
    const base = JSON.parse(JSON.stringify(flatten(this.players.map(p => p.playerPoints.filter(f => f.systemId === -33))).sort(firstBy('total', -1))))
    if (!base.length) return 'NA'

    var noTV = base.filter(f => !f.total)
    noTV.forEach(a => {
      const tvs = base.filter(f => f.total)
      const s = tvs.map(m => m.total).reduce((a, b) => a + b, 0)
      const b = s / tvs.length
      a.total = b * 0.9
    })

    const sum = base.map(m => m.total).reduce((a, b) => a + b, 0)
    var x = sum / base.length

    return fixedX(x, 2)
  }

  get movrG () {
    const base = JSON.parse(JSON.stringify(flatten(this.players.map(p => p.playerPoints.filter(f => f.systemId === -34))).sort(firstBy('total', -1))))
    if (!base.length) return 'NA'

    var noTV = base.filter(f => !f.total)
    noTV.forEach(a => {
      const tvs = base.filter(f => f.total)
      const s = tvs.map(m => m.total).reduce((a, b) => a + b, 0)
      const b = s / tvs.length
      a.total = b * 0.9
    })

    const sum = base.map(m => m.total).reduce((a, b) => a + b, 0)
    var x = sum / base.length

    return fixedX(x, 2)
  }

  get teamPoints () {
    console.log('here')
    const r = {}
    this.seedPoints.forEach(p => {
      r[p.systemId] = p.total
    })
    return r
  }

  get teamRatings () {
    const r = {}
    const systemIds = flatten(this.players.map(p => p.ratings.map(r => r.systemId)))
    systemIds.forEach(id => {
      const ratings = this.players.map(p => {
        const rating = p.getRating(id)
        return rating ? rating.currentValue || 0 : 0
      }).sort(firstBy(Number, -1))
      let val = 0
      const n = this.starters - ratings.length
      if (n > 0) {
        for (let i = 0; i < n; i++) {
          ratings.push(0)
        }
      }
      val = Math.max(...ratings) * 1000
      const starters = ratings.slice(0, this.starters)
      const a = average(starters)
      val = val + a
      r[id] = val
    })
    return r
  }

  ensureRatings (ids) {
    const r = {}
    ids.forEach(id => {
      r[id] = this.teamRatings[id] || 0
    })
    return r
  }

  get sortFinish () {
    return this.iFinish || this.finish || 9999
  }

  get field () {
    const tag = this.tags.find(f => f.startsWith('field-'))
    return tag ? tag.replace('field-', '') : null
  }

  get pointsLocked () {
    return this.players && this.players.filter(f => f.dtPointLock).length > 0
  }

  get pointsIssue () {
    return this.pointsLocked && this.players.filter(f => !f.dtPointLock).length > 0
  }

  get missingPoints () {
    return this.seedPoints.length === 0
  }

  get missingSomePoints () {
    return this.players.filter(f => !f.playerPoints || f.playerPoints.length === 0).length > 0
  }

  get logo () {
    let l = this.tags.find(f => f.startsWith('logo-'))
    if (l) {
      l = l.substring(5)
      return l.startsWith('http') ? l : `https://vblimages.blob.core.windows.net/images/${l}`
    }
    return false
  }

  get rosteredTeamId () {
    const t = this.tags.find(f => f.startsWith('rtId-'))
    return t && +t.substring(5)
  }

  get isRostered () {
    return !!this.rosteredTeamId
  }

  get publicTags () {
    return this.tags.filter(f => !f.startsWith('rtId-') && !f.startsWith('logo-') && !f.includes('-jersey-') && !f.startsWith('captain-'))
  }

  get captainIds () {
    return this.tags.filter(f => f.startsWith('captain-')).map(m => +m.substring(8))
  }

  isCaptain (id) {
    return this.captainIds.includes(id)
  }

  get captains () {
    return this.players.filter(f => this.isCaptain(f.ppId))
  }

  get captain () {
    return this.captains && this.captains.length > 0 ? this.captains[0].name : null
  }
}
